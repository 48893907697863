import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 156 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M102.8 15.5q-1.5-.45-2.7-1.2l1.5-2.8q1.05.75 2.4 1.2c.9.3 1.8.4 2.7.4.8 0 1.6-.1 2.3-.5.5-.2.9-.8.9-1.3s-.3-.9-.7-1c-.7-.3-1.4-.6-2.2-.8q-1.2-.3-2.4-.9c-.6-.3-1.2-.7-1.6-1.2-.5-.6-.7-1.4-.7-2.2 0-1 .3-2 .9-2.8s1.5-1.5 2.5-1.8c1.2-.4 2.4-.7 3.7-.6 1 0 2 .1 2.9.3.8.2 1.6.5 2.4 1L113.4 4c-.6-.4-1.2-.7-1.9-.9s-1.5-.3-2.2-.3c-.8 0-1.7.1-2.4.5-.5.3-.9.8-.9 1.4 0 .3.1.6.4.9l.9.6c.4.1.9.3 1.6.5.8.2 1.6.5 2.4.8.6.3 1.2.7 1.6 1.2.5.6.7 1.3.7 2.1 0 1-.3 2-.9 2.8s-1.5 1.5-2.5 1.8c-1.2.5-2.5.7-3.7.7-1.4-.1-2.5-.3-3.7-.6m37.9.2L143.8.2h6.2c1.6-.1 3.2.3 4.5 1.3 1.1.9 1.6 2.2 1.6 3.5 0 1.2-.3 2.4-.9 3.4s-1.5 1.8-2.6 2.2c-1.3.5-2.6.8-4 .8h-3.4l-.8 4.3zm5-7.1h3c1 .1 2-.2 2.8-.8.7-.6 1-1.5 1-2.3 0-.6-.2-1.3-.7-1.7-.6-.4-1.4-.6-2.1-.6h-2.8zm-12.6 7.1L136.2.2h3.6l-3.1 15.5zm-7.5 0 1.3-6.3h-7l-1.3 6.3H115L118.1.2h3.6l-1.2 6.1h7l1.2-6.1h3.6l-3.1 15.5zm-42.8 0L85.9.2h6.4c1.4 0 2.8.2 4.1.8 1.1.5 2 1.3 2.6 2.2.6 1 .9 2.2.9 3.4 0 1.7-.4 3.3-1.2 4.7s-2 2.5-3.4 3.2c-1.6.8-3.3 1.2-5 1.1h-7.5zm4.1-2.9h3.5c1.1 0 2.2-.2 3.1-.8.9-.5 1.6-1.2 2-2.1.5-.9.7-2 .7-3 .1-1-.4-2-1.1-2.7-.9-.7-2.1-1.1-3.3-1h-3.1zm-11.3 2.9L78.7.2h3.6l-3.1 15.5zm-15.9 0L62.8.2H69c1.6-.1 3.2.3 4.5 1.3 1.1.9 1.6 2.2 1.6 3.5 0 1.2-.3 2.4-.9 3.4s-1.5 1.8-2.6 2.2c-1.3.5-2.6.8-4 .8h-3.4l-.8 4.3zm5-7.1h3c1 .1 2-.2 2.8-.8.7-.6 1-1.5 1-2.3 0-.6-.2-1.3-.7-1.7-.6-.4-1.4-.6-2.1-.6h-2.8zm-10.2 7.1-.7-3.3h-7.2l-2 3.3h-3.8L50.8.2h3.5l3.8 15.5zm-6.2-6h4.9l-1.3-6zm-11.8 6-2.4-4.3h-3l-.9 4.3h-3.6L29.7.2H36c1.6-.1 3.1.3 4.4 1.3 1.1.9 1.7 2.2 1.6 3.5 0 1.3-.4 2.6-1.2 3.7s-2 1.8-3.2 2.2l2.7 4.8zm-4.9-7.1h3c1 .1 2-.2 2.8-.8.7-.6 1-1.5 1-2.3 0-.6-.2-1.3-.7-1.7-.6-.4-1.4-.6-2.1-.6h-2.8zm-19.4 5.9c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h9c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1zM1.1 8.9C.5 8.9 0 8.4 0 7.8s.5-1.1 1.1-1.1h22.2c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1zm7.8-5.6c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h15.5c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1z" }, null, -1)
  ])))
}
export default { render: render }