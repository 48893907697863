function useCart(name: string = 'default') {
  const { $sitewideConfig, $uiEvents, $forter, $cognito } = useNuxtApp()
  const rootStore = useRootStore()
  const { isIntegrationEnabled } = useUtils()
  const urls = useUrls()
  const receipt = useReceipt()

  const log = useLogger('useCart')
  const cartUrl = urls.getApiUrl('cart')
  const checkoutUrl = urls.getApiUrl('checkout')

  const stateKey = `cart-${name}`

  const cartApi = $fetch.create({
    baseURL: cartUrl,
    headers: {
      'x-site': $sitewideConfig.sitePrefix,
    },
  })

  const checkoutApi = $fetch.create({
    baseURL: checkoutUrl,
    headers: {
      'x-site': $sitewideConfig.sitePrefix,
    },
  })

  // Global state for the cart instance
  const state = useState<Cart>(stateKey, () => shallowRef(createEmptyCartState()))

  // If we don't have a cart id or created at then this cart instance hasn't been initialized yet.
  // Just checking for the id isn't enough because we set the id in the state before we fetch the cart.
  const isInitialized = computed(() => !!state.value.id && !!state.value.createdAt)

  // The last item in the cart that was modified.
  const lastItem = computed(() => {
    const items = [...state.value.items].sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))

    return items.shift()
  })

  // Local Methods
  function createEmptyCartState(id = ''): Cart {
    return {
      id,
      orderId: '',
      createdAt: '',
      expiresAt: '',
      customer: {
        permId: '',
      },
      giftCards: [],
      hasShippingProtection: false,
      insideSale: false,
      itemCount: 0,
      items: [],
      lastRequestedAt: '',
      leadSource: 'WEB',
      promoCodes: [],
      shortId: '',
      total: 0,
      updatedAt: '',
      summary: {
        cartSubtotal: 0,
        discountSubtotal: 0,
        giftCardSubtotal: 0,
        grandTotal: 0,
        installationSubtotal: 0,
        itemCount: 0,
        promoSubtotal: 0,
        shippingSubtotal: 0,
        taxSubtotal: 0,
        warrantySubtotal: 0,
      },
    }
  }

  function resetCartState(id?: string) {
    state.value = createEmptyCartState(id)
  }

  // Helper function to create a cart.
  async function createCart() {
    try {
      const resp = await cartApi<{ id: string }>(`/`, {
        method: 'POST',
        body: {
          customer: {
            permId: rootStore.originalPermId,
          },
        },
      })

      // Set the cart state with the id that we just created.
      resetCartState(resp.id)
    } catch (error) {
      // TODO: Do we really need to log these errors? Even when its just a network error that we can't control?
      // I could add an additional check to make sure its a error from the actual api call so we can log that
      log.error('Unable to create cart', { error })
    }
  }

  // Helper function to migrate a cart from a permId.
  async function migrateCartFromPermId(permId: string) {
    try {
      const resp = await cartApi<{ id: string }>(`/${permId}/migrate`, {
        method: 'POST',
      })

      // Set the cart state with the id that was just created after we migrated from the permId.
      resetCartState(resp.id)
    } catch (error) {
      log.error('Unable to migrate cart', { error })
    }
  }

  // Helper function to fetch a cart.
  async function fetchCart() {
    // Shouldn't fetch unless we have a cart id
    if (!state.value.id) return

    state.value = await cartApi<Cart>(`/${state.value.id}`)
  }

  // This is a higher-order function in that wraps another function (`fn`) with general cart actions.
  function cartActionWrapper<T extends (...args: any[]) => any>(fn: T): T {
    return (async (...args: Parameters<T>) => {
      try {
        // If the cart hasn't been initialized yet, then initialize it
        if (!state.value.id) await createCart()

        // Call the wrapped function
        const resp = await fn(...args)

        // Refresh the state of the cart
        await fetchCart()

        // Return the response if the passed function returned a response
        return resp
      } catch (error) {
        // If we get a cart not found error, then we reset the cart state so it can be reinitialized on the next request
        if (error.data?.code === 'CART_NOT_FOUND') {
          resetCartState()
        } else {
          throw error
        }
      }
    }) as T
  }

  // Public Methods

  // Initializes the cart instance.
  async function init(id?: string) {
    // if we don't have an id then we need to create a new cart.
    if (!id) {
      await createCart()
    }
    // if we have an id and it was a permId then we need to migrate that permId cart to a new cart.
    else if (isValidPermId(id)) {
      await migrateCartFromPermId(id)
    }
    // if we have an id and it was not a permId then we need to set the cart state.
    else {
      resetCartState(id)
    }

    // If we don't have an id at this point, then we need to throw an error because something is wrong.
    if (!state.value.id) throw new Error('Cart not initialized')

    // Fetch the cart state
    await fetchCart()
  }

  const deleteCart = cartActionWrapper(async () => {
    await cartApi(`/${state.value.id}`, {
      method: 'DELETE',
    })

    resetCartState()
  })

  // TODOLATER: Talk with mike about having the add to cart api call return the cart item that was just added.
  // This will allow us to get rid of the last item computed thing.
  const addItems = cartActionWrapper(async (items: AddItemsRequest[]) => {
    const formattedItems = items.map((item) => {
      const { sku, qty, fitmentData, meta } = item

      const formattedFitmentData = fitmentData
        ? {
            year: fitmentData.year,
            makeName: fitmentData.make,
            modelName: fitmentData.model,
            bedName: fitmentData.bed,
            bodyName: fitmentData.body,
            engineName: fitmentData.engine,
          }
        : undefined

      return {
        sku,
        qty,
        meta,
        fitmentData: formattedFitmentData,
      }
    })

    await cartApi(`/${state.value.id}/items`, {
      method: 'POST',
      body: {
        productList: formattedItems,
      },
    })
  })

  const updateItemQty = cartActionWrapper(async (id: string, qty: number) => {
    await cartApi(`/${state.value.id}/items/${id}` as string, {
      method: 'PATCH',
      body: {
        id: id,
        qty,
      },
    })
  })

  const removeItem = cartActionWrapper(async (id: string) => {
    // find the item being removed
    const product = state.value.items.find((item) => {
      return item.id === id
    })

    await cartApi(`/${state.value.id}/items/${id}` as string, {
      method: 'DELETE',
    })

    $uiEvents.$emit('removeFromCart', product)
  })

  const addItemWarranty = cartActionWrapper(async (id: string, planId: string) => {
    await cartApi(`/${state.value.id}/items/${id}/warranty` as string, {
      method: 'POST',
      body: {
        plan: planId,
      },
    })
  })

  const removeItemWarranty = cartActionWrapper(async (id: string) => {
    await cartApi(`/${state.value.id}/items/${id}/warranty` as string, {
      method: 'DELETE',
    })
  })

  const addItemInstallation = cartActionWrapper(
    async (id: string, installation: { quoteId: string; city: string; state: string }) => {
      await cartApi(`/${state.value.id}/items/${id}/installation` as string, {
        method: 'POST',
        body: {
          quoteId: installation.quoteId,
          city: installation.city,
          state: installation.state,
        },
      })
    }
  )

  const updateItemInstallation = cartActionWrapper(
    async (id: string, installation: { city: string; state: string; zip: string }) => {
      await cartApi(`/${state.value.id}/items/${id}/installation` as string, {
        method: 'PUT',
        body: {
          city: installation.city,
          state: installation.state,
          zip: installation.zip,
        },
      })
    }
  )

  const removeItemInstallation = cartActionWrapper(async (id: string) => {
    await cartApi(`/${state.value.id}/items/${id}/installation` as string, {
      method: 'DELETE',
    })
  })

  const addShippingProtection = cartActionWrapper(async () => {
    const resp = await cartApi<{ code: string }>(`/${state.value.id}/shipping-protection`, {
      method: 'POST',
    })

    if (resp.code !== 'SUCCESS') throw new Error(`Failed to add shipping protection.  Code: ${resp.code}`)
  })

  const removeShippingProtection = cartActionWrapper(async () => {
    const resp = await cartApi<{ code: string }>(`/${state.value.id}/shipping-protection`, {
      method: 'DELETE',
    })

    if (resp.code !== 'SUCCESS') throw new Error(`Failed to remove shipping protection.  Code: ${resp.code}`)
  })

  // This function doesn't modify the cart in any way, it just shares it so we don't need the full cart wrapper
  async function share(fullName: string, email: string) {
    await cartApi(`/${state.value.id}/share`, {
      method: 'POST',
      body: {
        name: fullName,
        email,
        url: `https://${$sitewideConfig.domain}/restore-cart/`,
      },
    })
  }

  const restore = cartActionWrapper(async (shortId: string, force: boolean) => {
    if (force) {
      try {
        await cartApi(`/${state.value.id}/abandonedcart`, {
          method: 'POST',
          body: {
            shortId,
          },
        })
      } catch (error) {
        log.error('Unable to restore abandoned cart', { error })
      }
    } else {
      try {
        await cartApi(`/${state.value.id}/restore`, {
          method: 'POST',
          body: {
            tempPermId: shortId,
          },
        })
      } catch (error) {
        log.error('Unable to restore cart', { error })
      }
    }
  })

  const addGiftCard = cartActionWrapper(async (token: string) => {
    await checkoutApi(`/${state.value.id}/giftcards`, {
      method: 'POST',
      body: {
        number: token,
      },
    })
  })

  const removeGiftCard = cartActionWrapper(async (token: string) => {
    const url = `/${state.value.id}/giftcards/${token}`
    await checkoutApi(url, {
      method: 'DELETE',
    })
  })

  const addPromoCode = cartActionWrapper(async (promoCode: string) => {
    const resp = await checkoutApi<{ code: string }>(`/${state.value.id}/coupons`, {
      method: 'POST',
      body: {
        promoCodeId: promoCode,
      },
    })

    if (resp.code === 'SUCCESS') {
      $uiEvents.$emit('promoCodeApplied', promoCode)
    }

    return resp
  })

  const removePromoCode = cartActionWrapper(async (promoCode: string) => {
    await checkoutApi(`/${state.value.id}/coupons/${promoCode}` as string, {
      method: 'DELETE',
    })

    $uiEvents.$emit('promoCodeRemoved', promoCode)
  })

  const setContact = cartActionWrapper(async (contact: { shipping?: CartContact; billing?: CartContact }) => {
    const { shipping, billing } = contact

    if (shipping) {
      await checkoutApi(`/${state.value.id}/shipping`, {
        method: 'POST',
        body: shipping,
      })
    }

    if (billing) {
      await checkoutApi(`/${state.value.id}/billing`, {
        method: 'POST',
        body: billing,
      })
    }
  })

  interface ProcessPaymentRequest {
    type: 'STRIPE' | 'STRIPE_PAYMENT_REQUEST' | 'READER' | 'PAYPAL' | 'AFFIRM' | 'GIFTCARD'
    raw?: any
    token?: string
  }

  async function processPayment(paymentRequest: ProcessPaymentRequest) {
    let type = paymentRequest.type

    if (type === 'STRIPE_PAYMENT_REQUEST') {
      type = 'STRIPE'
    }

    // We need to make sure the person checkout is the customer of the cart.
    // When dealing with paylinks, this is not the case so we need to update the customer before we process the token.
    // If we do not do this, then when the user fetches the receipt, the PII will be missing and will cause an error in the logging.
    if (state.value.customer.permId !== rootStore.originalPermId) {
      await updateCart({ permId: rootStore.originalPermId })
    }

    const headers: HeadersInit = {}

    if (isIntegrationEnabled('forter')) {
      headers['x-forter-token'] = $forter.getToken()
    }

    if (paymentRequest.type === 'READER' && isIntegrationEnabled('cognito')) {
      const token = await $cognito.getToken()

      if (token) headers.Authorization = `Bearer ${token}`
    }

    await checkoutApi<{ orderId: string }>(`/${state.value.id}/${type}`, {
      method: 'POST',
      headers,
      body: {
        raw: paymentRequest.raw,
        token: paymentRequest.token,
      },
    })

    // If the type is READER, we need to check if the order is completed manually. This is because the READER checkout is async
    // on the backend and we need to poll to see if the order is completed before we can send the user to the receipt page.
    // The READER type is only used for the retail employee checkout.
    if (type !== 'READER') await completeOrder(paymentRequest)
  }

  async function completeOrder(paymentRequest: ProcessPaymentRequest) {
    // get the receipt so we can emit the orderPlaced event
    const receiptData = await receipt.getReceipt(state.value.id)

    // Emit the orderPlaced event with the original payment request type
    $uiEvents.$emit('orderPlaced', { type: paymentRequest.type, receipt: receiptData })

    // Send to the receipt page
    await navigateTo(`/checkout/receipt/${state.value.id}/`)

    // After we checkout with a new cart, The cart becomes "used" and it can no longer be used
    // So the state needs to be reset so that this instance can be used again
    resetCartState()
  }

  interface UpdateCartRequest {
    permId?: string
    leadSource?: string
  }
  async function updateCart(data: UpdateCartRequest = {}) {
    const payload: Record<string, any> = {
      leadSource: data.leadSource,
    }

    if (data.permId) payload.customer = { permId: data.permId }

    await cartApi(`/${state.value.id}`, {
      method: 'PUT',
      body: payload,
    })
  }

  return {
    state,
    lastItem,
    isInitialized,
    init,
    refresh: fetchCart,
    deleteCart,
    addItems,
    updateItemQty,
    removeItem,
    addItemWarranty,
    removeItemWarranty,
    addItemInstallation,
    updateItemInstallation,
    removeItemInstallation,
    addShippingProtection,
    removeShippingProtection,
    share,
    restore,
    addGiftCard,
    removeGiftCard,
    addPromoCode,
    removePromoCode,
    setContact,
    processPayment,
    completeOrder,
  }
}

export default useCart
export type CartInstance = ReturnType<typeof useCart>

export interface AddItemsRequest {
  sku: string
  qty: number
  fitmentData?: Fitment
  meta?: {
    notes?: string
  }
}

export type CartLeadSource = 'WEB' | 'PHONE' | 'CHAT'

interface Cart {
  orderId: string
  expiresAt: string
  itemCount: number
  id: string
  customer: { permId: string }
  updatedAt: string
  lastRequestedAt: string
  total: number
  summary: {
    cartSubtotal: number
    discountSubtotal: number
    giftCardSubtotal: number
    grandTotal: number
    installationSubtotal: number
    itemCount: number
    promoSubtotal: number
    shippingSubtotal: number
    taxSubtotal: number
    warrantySubtotal: number
  }
  shippingContact?: CartContact
  billingContact?: CartContact
  giftCards: GiftCard[]
  promoCodes: PromoCode[]
  shortId: string
  createdAt: string
  insideSale: boolean
  items: CartItem[]
  hasShippingProtection: boolean
  leadSource: CartLeadSource
}

interface GiftCard {
  id: string
  price: number
  name: string
  token: string
}

interface PromoCode {
  name: string
}

export interface CartContact {
  fullName?: string
  firstName?: string
  lastName?: string
  email: string
  address1: string
  address2?: string
  city: string
  stateId: string
  zipcode: string
  country: string
  phone: string
  company?: string
  subscribe?: boolean
}

export interface CartItem {
  lineId: string
  id: string
  itemId: number
  fitmentData?: {
    year: string
    makeName: string
    modelName: string
    bedName?: string
    bodyName?: string
    engineName?: string
    appNote?: string
  }
  discount?: {
    amount: number
    rateFormatted: string
    endDate: string
    discountType: string
    text: string
    enabled: string
    startDate: string
  }
  promo?: {
    heading: string
    endDate: string
    qty: number
    text: string
    discountId: string
    enabled: string
    url: string
    startDate: string
  }
  qty: number
  image?: {
    title?: string
    key: string
    filename: string
  }
  productId: string
  originalPrice: number
  itemPrice: number
  info?: {
    key: string
    value: string
  }[]
  meta?: {
    notes?: string
  }
  mpn?: string
  sku: string
  skuSlug: string
  productName: string
  productLineName: string
  salePrice: number
  updatedAt: string
  productLineId: number
  productLineSlug: string
  productSlug: string
  category: string
  isVirtualItem?: boolean
  isFreeItem: boolean
  originalSubtotal: number
  itemSubtotal: number
  saleSubtotal: number
  shipping: {
    estimatedDeliveryDate?: {
      min: string
      max: string
    }
    isEligibleFreeShipping: boolean
    sameDay?: {
      cutoffTime: string
      startDayOfWeek: string
      endDayOfWeek: string
    }
  }
  hasInstallationGuide: boolean
  hasInstallationVideo: boolean
  installation?: Installation
  installationAvailable?: {
    id: string
    quoteId: string
    sellPrice: number
    expirationDate: string
    zip: string
    city: string
    state: string
  }
  warranty?: Warranty
  guaranteedFit: 'PHONE' | 'SITE'
}
export interface Warranty {
  termLength: number
  price: number
  id: string
  url: string
  imageUrl: string
  planType: string
  subtotal: number
}

export interface Installation {
  quoteId: string
  regularPrice: number
  sellPrice: number
  cost: number
  expirationDate: string
  zip: string
  subtotal: number
}
