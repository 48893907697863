import ConstructorClient from '@constructor-io/constructorio-client-javascript'

export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $uiEvents, $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration constructor 00')
  const runtimeConfig = useRuntimeConfig()
  const { isIntegrationEnabled } = useUtils()

  if (!isIntegrationEnabled('constructor')) return

  // Constructor Beacon
  onNuxtReady(() => {
    const scriptOnDemand = runScriptOnDemand()
    scriptOnDemand.addSrc('https://cnstrc.com/js/cust/realtruck_amZrnR.js')
    scriptOnDemand.load()
  })

  const apiKey: string = $sitewideConfig.config.constructorIndexKey

  if (!apiKey) return

  // Create Constructor Client
  const client = new ConstructorClient({
    apiKey,
    serviceUrl: runtimeConfig.public.constructorServiceUrl,
    sendTrackingEvents: true,
  })

  // We we get the orderPlaced event, trigger the purchase event
  $uiEvents.$on('orderPlaced', ({ receipt }: { receipt: Receipt }) => {
    client.tracker.trackPurchase({
      orderId: receipt.orderId,
      revenue: receipt.summary.cartSubtotal / 100,
      items: receipt.items.map((item) => ({
        itemId: item.productLineSlug,
        variationId: `${item.productLineSlug}_${item.productSlug}`,
        price: item.salePrice / 100,
        count: item.qty,
      })),
    })
  })

  return {
    provide: {
      constructor: {
        client,
      },
    },
  }
})
