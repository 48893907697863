export default defineNuxtPlugin(() => {
  const { $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('ketch')) return

  const ketchCode = $sitewideConfig.config.ketchCode

  runOnNuxtReady(() => {
    const script = document.createElement('script')
    script.innerText = `!function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=document.createElement("script");e.type="text/javascript",e.src="https://global.ketchcdn.com/web/v3/config/realtruck/${ketchCode}/boot.js",e.defer=e.async=!0,document.getElementsByTagName("head")[0].appendChild(e)}();`
    document.head.appendChild(script)
  })
})
