export default defineNuxtPlugin(() => {
  const { $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  const { $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration stripe')
  if (!isIntegrationEnabled('stripe')) return

  const apiKey = $sitewideConfig.config.stripeKey
  const script = runScriptOnDemand()

  let stripe: any

  script.addSrc(`https://js.stripe.com/v3/`)

  // Attempt to create an instance of Stripe
  function initStripe() {
    return new Promise((resolve) => {
      const maxAttempts = 10
      let attempts = 0

      // Make attempts to load stripe
      const interval = setInterval(() => {
        if (window.Stripe) {
          // Success
          clearInterval(interval)
          resolve(window.Stripe(apiKey))
        } else if (attempts >= maxAttempts) {
          // Fail
          clearInterval(interval)
          // By resolving Undefined we allow load() to try again on subsequent calls
          resolve(undefined)
        } else {
          // Retry
          attempts++
        }
      }, 100)
    })
  }

  function load() {
    return new Promise((resolve) => {
      script.addCallback(async () => {
        // Use the current instance of stripe if it exists.
        if (stripe) return resolve(stripe)

        // Attempt to initStripe then resolve to either a stripe intance or undefined
        // If stripe is undefined we handle that in the code where we try to load it.
        stripe = await initStripe()
        resolve(stripe)
      })

      script.addErrorCallback(() => {
        resolve(null)
      })

      script.load()
    })
  }

  return {
    provide: {
      stripe: {
        load,
      },
    },
  }
})
