import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 25.5 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12.2 14.5c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h9c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1zM1.1 8.9C.5 8.9 0 8.4 0 7.8s.5-1.1 1.1-1.1h22.2c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1zm7.8-5.6c-.6 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1h15.5c.6 0 1.1.5 1.1 1.1s-.5 1.1-1.1 1.1z" }, null, -1)
  ])))
}
export default { render: render }