<template lang="pug">
//- We need to hide the base component when the overlay is open while editing
//- because HeadlessUI portals the overlay to the bottom of <body> tag and
//- overlay z-index is below Storyblok widgets causing widgets to render above overlays
div(:class='{ hidden: overlayIsOpen && $storyblok.isEditing.value }')
  CmsEditorTabs(:data='[{ name: "Open Result Modal", clickHandler: () => openModal() }]')

  div(v-if='!isIntegrationEnabled("geolocation") || !isIntegrationEnabled("installernet")')
    p(class='p-2 mb-4 text-sm text-center bg-danger-light border border-danger rounded')
      | Features are not enabled to display this widget #[span(v-if='$storyblok.isEditing.value') outside of preview mode]

  div(
    v-if='isIntegrationEnabled("geolocation") && isIntegrationEnabled("installernet")'
    :class='{ relative: $storyblok.isEditing.value }'
  )
    BaseForm
      div(class='flex')
        BaseFormInput(
          v-model='zipcode'
          :classes='{ outer: "$reset w-full", inner: "$reset", input: "$reset bg-white border rounded-r-none border-gray-light p-3 w-full rounded appearance-none text-gray-darkest" }'
          maxlength='5'
          :placeholder='data.placeholderText'
          @keyup.enter='lookupInstallers'
        )
        BaseButton(
          aria-label='Submit'
          class='rounded-l-none'
          :class='[fontColorClasses, { "md:px-6": data.buttonText }]'
          :color='data.buttonColor'
          :is-disabled='zipcode.length < 5'
          :is-waiting='isLoading'
          type='submit'
          @click='lookupInstallers'
        )
          template(v-if='data.buttonText') {{ data.buttonText }}
          template(v-else)
            ChevronRightIcon(class='w-5 h-5 stroke-current')

    div(v-if='errorText' class='text-danger text-xs')
      | {{ errorText }}

    //- Shade for storyblok editor to prevent clicks
    div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')

  CmsWidgetInstallernetResultsModal(:results='results')
    template(#success)
      div(v-if='data.successWidgets')
        template(v-for='widget in data.successWidgets')
          slot(:data='widget')

    template(#failure)
      div(v-if='data.failureWidgets')
        template(v-for='widget in data.failureWidgets')
          slot(:data='widget')
</template>

<script setup lang="ts">
import ChevronRightIcon from '@/assets/chevronRight.svg?component'

const { $overlay, $storyblok, $geolocation } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
const { getApiUrl } = useUrls()

interface InstallernetWidget extends Widget {
  buttonFontColor: string
  placeholderText: string
  buttonColor: ButtonColor
  buttonText: string
  successWidgets: Widgets
  failureWidgets: Widgets
}
const { data } = defineProps<{
  data: InstallernetWidget
}>()

const zipcode = ref('')
const isLoading = ref(false)
const errorText = ref('')
const results: Ref<InstallernetWidgetResults[]> = ref([])

const fontColorClasses = computed(() => {
  return generateFontColorClass(data.buttonFontColor)
})

const overlayIsOpen = computed(() => {
  return !!$overlay?.activeOverlay?.value
})

function openModal() {
  $overlay.open('installerNet')
}

async function lookupInstallers() {
  // Early out if we are in the middle of a request.
  if (isLoading.value) return

  isLoading.value = true
  errorText.value = ''

  // validate that the zip is 5 digits
  if (!/^\d{5}$/.test(zipcode.value)) {
    errorText.value = `Please enter a valid 5-digit zip code`
    isLoading.value = false
    return
  }

  try {
    await $geolocation.setGeoLocationByZip(zipcode.value)

    const url = getApiUrl('installation') + `installers?zip=${zipcode.value}`
    const installerResponse: { data: InstallernetWidgetResults[] } = await $fetch(url)

    // If we got a valid response from the api then set the installers
    if (installerResponse?.data) results.value = installerResponse.data

    // Show the installer modal
    openModal()
  } catch (error) {
    // If we have an error then we need to clear out the results
    results.value = []

    // If its an not found error then we still want to show the modal
    // $fetch FetchError returns a response with a _data object
    if (error?.response?._data?.code === 'NO_INSTALLERS_FOUND') {
      openModal()
    } else {
      errorText.value = 'Please enter a valid zip code'
    }
  }

  isLoading.value = false
}

declare global {
  interface InstallernetWidgetResults {
    city: string
    state: string
    distance: string
  }
}
</script>
