
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93tTVZEUyhkLMeta } from "/home/circleci/project/pages/[...slug].vue?macro=true";
import { default as index2O1LHDN0wSMeta } from "/home/circleci/project/pages/account/index.vue?macro=true";
import { default as indexyyOwkuud4MMeta } from "/home/circleci/project/pages/brands/index.vue?macro=true";
import { default as AlgoliaVersiongMUpW3xMmQMeta } from "/home/circleci/project/pages/catalog/AlgoliaVersion.vue?macro=true";
import { default as ConstructorVersionifxkKhWko1Meta } from "/home/circleci/project/pages/catalog/ConstructorVersion.vue?macro=true";
import { default as index26YCHo2LcqMeta } from "/home/circleci/project/pages/catalog/index.vue?macro=true";
import { default as addressbjRId8l6YOMeta } from "/home/circleci/project/pages/checkout/address.vue?macro=true";
import { default as cartrsANFfKiJrMeta } from "/home/circleci/project/pages/checkout/cart.vue?macro=true";
import { default as indexjH3HJhNdYXMeta } from "/home/circleci/project/pages/checkout/index.vue?macro=true";
import { default as paymentfnfldZ1ZF2Meta } from "/home/circleci/project/pages/checkout/payment.vue?macro=true";
import { default as _91id_93C7j3tpQHfAMeta } from "/home/circleci/project/pages/checkout/receipt/[id].vue?macro=true";
import { default as checkoutYfoiHJuOVGMeta } from "/home/circleci/project/pages/checkout.vue?macro=true";
import { default as comparewqk5pyNZOwMeta } from "/home/circleci/project/pages/compare.vue?macro=true";
import { default as indexn7g829UDeSMeta } from "/home/circleci/project/pages/dealer-locator/index.vue?macro=true";
import { default as internationalL3ajvKGiRhMeta } from "/home/circleci/project/pages/dealer-locator/international.vue?macro=true";
import { default as dealer_45locator0Q68h6EjIJMeta } from "/home/circleci/project/pages/dealer-locator.vue?macro=true";
import { default as indexefdv2ztdQCMeta } from "/home/circleci/project/pages/installation-instructions/index.vue?macro=true";
import { default as newsletter_45unsubscribeXHsEul8NofMeta } from "/home/circleci/project/pages/newsletter-unsubscribe.vue?macro=true";
import { default as _91orderId_93NsV71ikILEMeta } from "/home/circleci/project/pages/order/status/[orderId].vue?macro=true";
import { default as _91trackingId_93SkgJy2RQzgMeta } from "/home/circleci/project/pages/order/tracking/[trackingId].vue?macro=true";
import { default as index7uggIwbZj5Meta } from "/home/circleci/project/pages/pay/index.vue?macro=true";
import { default as affirmA7AtKF0z0bMeta } from "/home/circleci/project/pages/paylink/[id]/affirm.vue?macro=true";
import { default as indexLFriihQ7cZMeta } from "/home/circleci/project/pages/paylink/[id]/index.vue?macro=true";
import { default as paymentL3HP9Atp4NMeta } from "/home/circleci/project/pages/paylink/[id]/payment.vue?macro=true";
import { default as index3r9l1RI7ZyMeta } from "/home/circleci/project/pages/paylink/index.vue?macro=true";
import { default as paylinkhfxLyo6IyQMeta } from "/home/circleci/project/pages/paylink.vue?macro=true";
import { default as indexPovt5JUlOnMeta } from "/home/circleci/project/pages/product/index.vue?macro=true";
import { default as restore_45cartjX33W5LTWIMeta } from "/home/circleci/project/pages/restore-cart.vue?macro=true";
import { default as index9wMWLIWwfaMeta } from "/home/circleci/project/pages/sales-contact/index.vue?macro=true";
import { default as indexDdJDyAApHyMeta } from "/home/circleci/project/pages/sku-lookup/index.vue?macro=true";
import { default as thank_45youhlVDdkqNlaMeta } from "/home/circleci/project/pages/thank-you.vue?macro=true";
import { default as caltrendwnGzxOfNb8Meta } from "/home/circleci/project/pages/third-party/caltrend.vue?macro=true";
import { default as configure_45techyQWnP5YEEjMeta } from "/home/circleci/project/pages/third-party/configure-tech.vue?macro=true";
import { default as _91_46_46_46slug_93SJ2in5NkxuMeta } from "@/pages/[...slug].vue?macro=true";
import { default as cancelledPhRtcZ4NIAMeta } from "/home/circleci/project/integrations/affirm/pages/cancelled.vue?macro=true";
import { default as successNiqAacRTI9Meta } from "/home/circleci/project/integrations/affirm/pages/success.vue?macro=true";
import { default as configuratorYd0pQw1J9HMeta } from "/home/circleci/project/integrations/protex/pages/configurator.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93tTVZEUyhkLMeta?.name ?? "slug",
    path: _91_46_46_46slug_93tTVZEUyhkLMeta?.path ?? "/:slug(.*)*",
    props: _91_46_46_46slug_93tTVZEUyhkLMeta?.props ?? false,
    meta: _91_46_46_46slug_93tTVZEUyhkLMeta || {},
    alias: _91_46_46_46slug_93tTVZEUyhkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93tTVZEUyhkLMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[...slug].vue")
  },
  {
    name: index2O1LHDN0wSMeta?.name ?? "account",
    path: index2O1LHDN0wSMeta?.path ?? "/account",
    props: index2O1LHDN0wSMeta?.props ?? false,
    meta: index2O1LHDN0wSMeta || {},
    alias: index2O1LHDN0wSMeta?.alias || [],
    redirect: index2O1LHDN0wSMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/index.vue")
  },
  {
    name: indexyyOwkuud4MMeta?.name ?? "brands",
    path: indexyyOwkuud4MMeta?.path ?? "/brands",
    props: indexyyOwkuud4MMeta?.props ?? false,
    meta: indexyyOwkuud4MMeta || {},
    alias: indexyyOwkuud4MMeta?.alias || [],
    redirect: indexyyOwkuud4MMeta?.redirect,
    component: () => import("/home/circleci/project/pages/brands/index.vue")
  },
  {
    name: AlgoliaVersiongMUpW3xMmQMeta?.name ?? "catalog-AlgoliaVersion",
    path: AlgoliaVersiongMUpW3xMmQMeta?.path ?? "/catalog/AlgoliaVersion",
    props: AlgoliaVersiongMUpW3xMmQMeta?.props ?? false,
    meta: AlgoliaVersiongMUpW3xMmQMeta || {},
    alias: AlgoliaVersiongMUpW3xMmQMeta?.alias || [],
    redirect: AlgoliaVersiongMUpW3xMmQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/AlgoliaVersion.vue")
  },
  {
    name: ConstructorVersionifxkKhWko1Meta?.name ?? "catalog-ConstructorVersion",
    path: ConstructorVersionifxkKhWko1Meta?.path ?? "/catalog/ConstructorVersion",
    props: ConstructorVersionifxkKhWko1Meta?.props ?? false,
    meta: ConstructorVersionifxkKhWko1Meta || {},
    alias: ConstructorVersionifxkKhWko1Meta?.alias || [],
    redirect: ConstructorVersionifxkKhWko1Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/ConstructorVersion.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "brand",
    path: index26YCHo2LcqMeta?.path ?? "/b/:brandSlug",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "brand-mmy",
    path: index26YCHo2LcqMeta?.path ?? "/b/:brandSlug/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "category-custom",
    path: index26YCHo2LcqMeta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/f/:facetSlug",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "category-custom-mmy",
    path: index26YCHo2LcqMeta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/f/:facetSlug/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "category",
    path: index26YCHo2LcqMeta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "category-mmy",
    path: index26YCHo2LcqMeta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "mmy",
    path: index26YCHo2LcqMeta?.path ?? "/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "new-products",
    path: index26YCHo2LcqMeta?.path ?? "/new-products",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "new-products-mmy",
    path: index26YCHo2LcqMeta?.path ?? "/new-products/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "specials",
    path: index26YCHo2LcqMeta?.path ?? "/specials",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "specials-mmy",
    path: index26YCHo2LcqMeta?.path ?? "/specials/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: { ...(index26YCHo2LcqMeta || {}), ...{"isFitable":true} },
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: checkoutYfoiHJuOVGMeta?.name ?? undefined,
    path: checkoutYfoiHJuOVGMeta?.path ?? "/checkout",
    props: checkoutYfoiHJuOVGMeta?.props ?? false,
    meta: checkoutYfoiHJuOVGMeta || {},
    alias: checkoutYfoiHJuOVGMeta?.alias || [],
    redirect: checkoutYfoiHJuOVGMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout.vue"),
    children: [
  {
    name: addressbjRId8l6YOMeta?.name ?? "checkout-address",
    path: addressbjRId8l6YOMeta?.path ?? "address",
    props: addressbjRId8l6YOMeta?.props ?? false,
    meta: addressbjRId8l6YOMeta || {},
    alias: addressbjRId8l6YOMeta?.alias || [],
    redirect: addressbjRId8l6YOMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/address.vue")
  },
  {
    name: cartrsANFfKiJrMeta?.name ?? "checkout-cart",
    path: cartrsANFfKiJrMeta?.path ?? "cart",
    props: cartrsANFfKiJrMeta?.props ?? false,
    meta: cartrsANFfKiJrMeta || {},
    alias: cartrsANFfKiJrMeta?.alias || [],
    redirect: cartrsANFfKiJrMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/cart.vue")
  },
  {
    name: indexjH3HJhNdYXMeta?.name ?? "checkout",
    path: indexjH3HJhNdYXMeta?.path ?? "",
    props: indexjH3HJhNdYXMeta?.props ?? false,
    meta: indexjH3HJhNdYXMeta || {},
    alias: indexjH3HJhNdYXMeta?.alias || [],
    redirect: indexjH3HJhNdYXMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/index.vue")
  },
  {
    name: paymentfnfldZ1ZF2Meta?.name ?? "checkout-payment",
    path: paymentfnfldZ1ZF2Meta?.path ?? "payment",
    props: paymentfnfldZ1ZF2Meta?.props ?? false,
    meta: paymentfnfldZ1ZF2Meta || {},
    alias: paymentfnfldZ1ZF2Meta?.alias || [],
    redirect: paymentfnfldZ1ZF2Meta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/payment.vue")
  },
  {
    name: _91id_93C7j3tpQHfAMeta?.name ?? "checkout-receipt-id",
    path: _91id_93C7j3tpQHfAMeta?.path ?? "receipt/:id()",
    props: _91id_93C7j3tpQHfAMeta?.props ?? false,
    meta: _91id_93C7j3tpQHfAMeta || {},
    alias: _91id_93C7j3tpQHfAMeta?.alias || [],
    redirect: _91id_93C7j3tpQHfAMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/receipt/[id].vue")
  }
]
  },
  {
    name: comparewqk5pyNZOwMeta?.name ?? "compare",
    path: comparewqk5pyNZOwMeta?.path ?? "/compare",
    props: comparewqk5pyNZOwMeta?.props ?? false,
    meta: comparewqk5pyNZOwMeta || {},
    alias: comparewqk5pyNZOwMeta?.alias || [],
    redirect: comparewqk5pyNZOwMeta?.redirect,
    component: () => import("/home/circleci/project/pages/compare.vue")
  },
  {
    name: dealer_45locator0Q68h6EjIJMeta?.name ?? undefined,
    path: dealer_45locator0Q68h6EjIJMeta?.path ?? "/dealer-locator",
    props: dealer_45locator0Q68h6EjIJMeta?.props ?? false,
    meta: dealer_45locator0Q68h6EjIJMeta || {},
    alias: dealer_45locator0Q68h6EjIJMeta?.alias || [],
    redirect: dealer_45locator0Q68h6EjIJMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator.vue"),
    children: [
  {
    name: indexn7g829UDeSMeta?.name ?? "dealer-locator",
    path: indexn7g829UDeSMeta?.path ?? "",
    props: indexn7g829UDeSMeta?.props ?? false,
    meta: indexn7g829UDeSMeta || {},
    alias: indexn7g829UDeSMeta?.alias || [],
    redirect: indexn7g829UDeSMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator/index.vue")
  },
  {
    name: internationalL3ajvKGiRhMeta?.name ?? "dealer-locator-international",
    path: internationalL3ajvKGiRhMeta?.path ?? "international",
    props: internationalL3ajvKGiRhMeta?.props ?? false,
    meta: internationalL3ajvKGiRhMeta || {},
    alias: internationalL3ajvKGiRhMeta?.alias || [],
    redirect: internationalL3ajvKGiRhMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator/international.vue")
  }
]
  },
  {
    name: indexefdv2ztdQCMeta?.name ?? "installation-instructions",
    path: indexefdv2ztdQCMeta?.path ?? "/installation-instructions",
    props: indexefdv2ztdQCMeta?.props ?? false,
    meta: indexefdv2ztdQCMeta || {},
    alias: indexefdv2ztdQCMeta?.alias || [],
    redirect: indexefdv2ztdQCMeta?.redirect,
    component: () => import("/home/circleci/project/pages/installation-instructions/index.vue")
  },
  {
    name: newsletter_45unsubscribeXHsEul8NofMeta?.name ?? "newsletter-unsubscribe",
    path: newsletter_45unsubscribeXHsEul8NofMeta?.path ?? "/newsletter-unsubscribe",
    props: newsletter_45unsubscribeXHsEul8NofMeta?.props ?? false,
    meta: newsletter_45unsubscribeXHsEul8NofMeta || {},
    alias: newsletter_45unsubscribeXHsEul8NofMeta?.alias || [],
    redirect: newsletter_45unsubscribeXHsEul8NofMeta?.redirect,
    component: () => import("/home/circleci/project/pages/newsletter-unsubscribe.vue")
  },
  {
    name: _91orderId_93NsV71ikILEMeta?.name ?? "order-status-orderId",
    path: _91orderId_93NsV71ikILEMeta?.path ?? "/order/status/:orderId()",
    props: _91orderId_93NsV71ikILEMeta?.props ?? false,
    meta: _91orderId_93NsV71ikILEMeta || {},
    alias: _91orderId_93NsV71ikILEMeta?.alias || [],
    redirect: _91orderId_93NsV71ikILEMeta?.redirect,
    component: () => import("/home/circleci/project/pages/order/status/[orderId].vue")
  },
  {
    name: _91trackingId_93SkgJy2RQzgMeta?.name ?? "order-tracking-trackingId",
    path: _91trackingId_93SkgJy2RQzgMeta?.path ?? "/order/tracking/:trackingId()",
    props: _91trackingId_93SkgJy2RQzgMeta?.props ?? false,
    meta: _91trackingId_93SkgJy2RQzgMeta || {},
    alias: _91trackingId_93SkgJy2RQzgMeta?.alias || [],
    redirect: _91trackingId_93SkgJy2RQzgMeta?.redirect,
    component: () => import("/home/circleci/project/pages/order/tracking/[trackingId].vue")
  },
  {
    name: index7uggIwbZj5Meta?.name ?? "pay",
    path: index7uggIwbZj5Meta?.path ?? "/pay",
    props: index7uggIwbZj5Meta?.props ?? false,
    meta: index7uggIwbZj5Meta || {},
    alias: index7uggIwbZj5Meta?.alias || [],
    redirect: index7uggIwbZj5Meta?.redirect,
    component: () => import("/home/circleci/project/pages/pay/index.vue")
  },
  {
    name: paylinkhfxLyo6IyQMeta?.name ?? undefined,
    path: paylinkhfxLyo6IyQMeta?.path ?? "/paylink",
    props: paylinkhfxLyo6IyQMeta?.props ?? false,
    meta: paylinkhfxLyo6IyQMeta || {},
    alias: paylinkhfxLyo6IyQMeta?.alias || [],
    redirect: paylinkhfxLyo6IyQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink.vue"),
    children: [
  {
    name: affirmA7AtKF0z0bMeta?.name ?? "paylink-id-affirm",
    path: affirmA7AtKF0z0bMeta?.path ?? ":id()/affirm",
    props: affirmA7AtKF0z0bMeta?.props ?? false,
    meta: affirmA7AtKF0z0bMeta || {},
    alias: affirmA7AtKF0z0bMeta?.alias || [],
    redirect: affirmA7AtKF0z0bMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/affirm.vue")
  },
  {
    name: indexLFriihQ7cZMeta?.name ?? "paylink-id",
    path: indexLFriihQ7cZMeta?.path ?? ":id()",
    props: indexLFriihQ7cZMeta?.props ?? false,
    meta: indexLFriihQ7cZMeta || {},
    alias: indexLFriihQ7cZMeta?.alias || [],
    redirect: indexLFriihQ7cZMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/index.vue")
  },
  {
    name: paymentL3HP9Atp4NMeta?.name ?? "paylink-id-payment",
    path: paymentL3HP9Atp4NMeta?.path ?? ":id()/payment",
    props: paymentL3HP9Atp4NMeta?.props ?? false,
    meta: paymentL3HP9Atp4NMeta || {},
    alias: paymentL3HP9Atp4NMeta?.alias || [],
    redirect: paymentL3HP9Atp4NMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/payment.vue")
  },
  {
    name: index3r9l1RI7ZyMeta?.name ?? "paylink",
    path: index3r9l1RI7ZyMeta?.path ?? "",
    props: index3r9l1RI7ZyMeta?.props ?? false,
    meta: index3r9l1RI7ZyMeta || {},
    alias: index3r9l1RI7ZyMeta?.alias || [],
    redirect: index3r9l1RI7ZyMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/index.vue")
  }
]
  },
  {
    name: indexPovt5JUlOnMeta?.name ?? "product",
    path: indexPovt5JUlOnMeta?.path ?? "/p/:productLine",
    props: indexPovt5JUlOnMeta?.props ?? false,
    meta: { ...(indexPovt5JUlOnMeta || {}), ...{"isFitable":true} },
    alias: indexPovt5JUlOnMeta?.alias || [],
    redirect: indexPovt5JUlOnMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: indexPovt5JUlOnMeta?.name ?? "product-mmy",
    path: indexPovt5JUlOnMeta?.path ?? "/p/:productLine/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexPovt5JUlOnMeta?.props ?? false,
    meta: { ...(indexPovt5JUlOnMeta || {}), ...{"isFitable":true} },
    alias: indexPovt5JUlOnMeta?.alias || [],
    redirect: indexPovt5JUlOnMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: indexPovt5JUlOnMeta?.name ?? "sku",
    path: indexPovt5JUlOnMeta?.path ?? "/p/:productLine/:skuSlug",
    props: indexPovt5JUlOnMeta?.props ?? false,
    meta: indexPovt5JUlOnMeta || {},
    alias: indexPovt5JUlOnMeta?.alias || [],
    redirect: indexPovt5JUlOnMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: restore_45cartjX33W5LTWIMeta?.name ?? "restore-cart",
    path: restore_45cartjX33W5LTWIMeta?.path ?? "/restore-cart",
    props: restore_45cartjX33W5LTWIMeta?.props ?? false,
    meta: restore_45cartjX33W5LTWIMeta || {},
    alias: restore_45cartjX33W5LTWIMeta?.alias || [],
    redirect: restore_45cartjX33W5LTWIMeta?.redirect,
    component: () => import("/home/circleci/project/pages/restore-cart.vue")
  },
  {
    name: index9wMWLIWwfaMeta?.name ?? "sales-contact",
    path: index9wMWLIWwfaMeta?.path ?? "/sales-contact",
    props: index9wMWLIWwfaMeta?.props ?? false,
    meta: index9wMWLIWwfaMeta || {},
    alias: index9wMWLIWwfaMeta?.alias || [],
    redirect: index9wMWLIWwfaMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sales-contact/index.vue")
  },
  {
    name: indexDdJDyAApHyMeta?.name ?? "sku-lookup",
    path: indexDdJDyAApHyMeta?.path ?? "/sku-lookup",
    props: indexDdJDyAApHyMeta?.props ?? false,
    meta: indexDdJDyAApHyMeta || {},
    alias: indexDdJDyAApHyMeta?.alias || [],
    redirect: indexDdJDyAApHyMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sku-lookup/index.vue")
  },
  {
    name: thank_45youhlVDdkqNlaMeta?.name ?? "thank-you",
    path: thank_45youhlVDdkqNlaMeta?.path ?? "/thank-you",
    props: thank_45youhlVDdkqNlaMeta?.props ?? false,
    meta: thank_45youhlVDdkqNlaMeta || {},
    alias: thank_45youhlVDdkqNlaMeta?.alias || [],
    redirect: thank_45youhlVDdkqNlaMeta?.redirect,
    component: () => import("/home/circleci/project/pages/thank-you.vue")
  },
  {
    name: caltrendwnGzxOfNb8Meta?.name ?? "third-party-caltrend",
    path: caltrendwnGzxOfNb8Meta?.path ?? "/third-party/caltrend",
    props: caltrendwnGzxOfNb8Meta?.props ?? false,
    meta: caltrendwnGzxOfNb8Meta || {},
    alias: caltrendwnGzxOfNb8Meta?.alias || [],
    redirect: caltrendwnGzxOfNb8Meta?.redirect,
    component: () => import("/home/circleci/project/pages/third-party/caltrend.vue")
  },
  {
    name: configure_45techyQWnP5YEEjMeta?.name ?? "third-party-configure-tech",
    path: configure_45techyQWnP5YEEjMeta?.path ?? "/third-party/configure-tech",
    props: configure_45techyQWnP5YEEjMeta?.props ?? false,
    meta: configure_45techyQWnP5YEEjMeta || {},
    alias: configure_45techyQWnP5YEEjMeta?.alias || [],
    redirect: configure_45techyQWnP5YEEjMeta?.redirect,
    component: () => import("/home/circleci/project/pages/third-party/configure-tech.vue")
  },
  {
    name: _91_46_46_46slug_93SJ2in5NkxuMeta?.name ?? "home",
    path: _91_46_46_46slug_93SJ2in5NkxuMeta?.path ?? "/",
    props: _91_46_46_46slug_93SJ2in5NkxuMeta?.props ?? false,
    meta: _91_46_46_46slug_93SJ2in5NkxuMeta || {},
    alias: _91_46_46_46slug_93SJ2in5NkxuMeta?.alias || [],
    redirect: _91_46_46_46slug_93SJ2in5NkxuMeta?.redirect,
    component: () => import("@/pages/[...slug].vue")
  },
  {
    name: index26YCHo2LcqMeta?.name ?? "search",
    path: index26YCHo2LcqMeta?.path ?? "/search",
    props: index26YCHo2LcqMeta?.props ?? false,
    meta: index26YCHo2LcqMeta || {},
    alias: index26YCHo2LcqMeta?.alias || [],
    redirect: index26YCHo2LcqMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: cancelledPhRtcZ4NIAMeta?.name ?? "affirm-cancelled",
    path: cancelledPhRtcZ4NIAMeta?.path ?? "/affirm/cancelled",
    props: cancelledPhRtcZ4NIAMeta?.props ?? false,
    meta: cancelledPhRtcZ4NIAMeta || {},
    alias: cancelledPhRtcZ4NIAMeta?.alias || [],
    redirect: cancelledPhRtcZ4NIAMeta?.redirect,
    component: () => import("/home/circleci/project/integrations/affirm/pages/cancelled.vue")
  },
  {
    name: successNiqAacRTI9Meta?.name ?? "affirm-success",
    path: successNiqAacRTI9Meta?.path ?? "/affirm/success",
    props: successNiqAacRTI9Meta?.props ?? false,
    meta: successNiqAacRTI9Meta || {},
    alias: successNiqAacRTI9Meta?.alias || [],
    redirect: successNiqAacRTI9Meta?.redirect,
    component: () => import("/home/circleci/project/integrations/affirm/pages/success.vue")
  },
  {
    name: configuratorYd0pQw1J9HMeta?.name ?? "protex-configurator",
    path: configuratorYd0pQw1J9HMeta?.path ?? "/protex/configurator",
    props: configuratorYd0pQw1J9HMeta?.props ?? false,
    meta: configuratorYd0pQw1J9HMeta || {},
    alias: configuratorYd0pQw1J9HMeta?.alias || [],
    redirect: configuratorYd0pQw1J9HMeta?.redirect,
    component: () => import("/home/circleci/project/integrations/protex/pages/configurator.vue")
  }
]