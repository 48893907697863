// For core fitment data and getters
// no need to import defineStore and acceptHMRUpdate
export const useFitmentStore = defineStore('Fitment', () => {
  const { isProduction } = useUtils()

  const year = ref<string>()
  const make = ref<string>()
  const makeSlug = ref<string>()
  const model = ref<string>()
  const modelSlug = ref<string>()
  const bed = ref<string>()
  const bedSlug = ref<string>()
  const body = ref<string>()
  const bodySlug = ref<string>()
  const engine = ref<string>()
  const engineSlug = ref<string>()
  const storeInCookie = ref(true)

  const cookie = useCookie('fitmentData', {
    maxAge: 400 * 24 * 60 * 60, // chrome has a max of 400d, safari is much less (24h-7d?)
    secure: isProduction(),
    sameSite: 'lax',
    path: '/',
  })

  const fitmentFormatted = computed(() => {
    const arr = []

    if (year.value) arr.push(year.value)
    if (make.value) arr.push(make.value)
    if (model.value) arr.push(model.value)
    return arr.join(' ')
  })

  const fitmentFormattedSecondary = computed(() => {
    const arr = []

    if (body.value && bodySlug.value !== 'n-a') arr.push(body.value)
    if (bed.value && bedSlug.value !== 'n-a') arr.push(bed.value)
    if (engine.value && engineSlug.value !== 'n-a') arr.push(engine.value)
    return arr.join(', ')
  })

  function isKey(str: string): str is FitmentKey {
    return FitmentKeys.includes(str as any)
  }

  // This function will allow use to set a flag so the store knows to not update the cookie
  function disableCookie() {
    storeInCookie.value = false
  }

  function init() {
    const val = cookie.value
    if (!val || typeof val !== 'object' || Array.isArray(val)) return
    update(val)
  }

  function update(newFitment: Fitment) {
    const shouldMerge =
      year.value === newFitment.year && make.value === newFitment.make && model.value === newFitment.model

    if (!shouldMerge) $reset()
    $patch(newFitment)

    if (!storeInCookie.value) return

    cookie.value = JSON.stringify(newFitment)
  }

  function clear() {
    $reset()

    if (!storeInCookie.value) return

    cookie.value = undefined
  }

  function $reset() {
    year.value = undefined
    make.value = undefined
    makeSlug.value = undefined
    model.value = undefined
    modelSlug.value = undefined
    bed.value = undefined
    bedSlug.value = undefined
    body.value = undefined
    bodySlug.value = undefined
    engine.value = undefined
    engineSlug.value = undefined
  }

  function $patch(newFitment: Fitment) {
    year.value = newFitment.year || year.value
    make.value = newFitment.make || make.value
    makeSlug.value = newFitment.makeSlug || makeSlug.value
    model.value = newFitment.model || model.value
    modelSlug.value = newFitment.modelSlug || modelSlug.value
    bed.value = newFitment.bed || bed.value
    bedSlug.value = newFitment.bedSlug || bedSlug.value
    body.value = newFitment.body || body.value
    bodySlug.value = newFitment.bodySlug || bodySlug.value
    engine.value = newFitment.engine || engine.value
    engineSlug.value = newFitment.engineSlug || engineSlug.value
  }

  return {
    year,
    make,
    makeSlug,
    model,
    modelSlug,
    bed,
    bedSlug,
    body,
    bodySlug,
    engine,
    engineSlug,
    isKey,
    fitmentFormatted,
    fitmentFormattedSecondary,
    disableCookie,
    update,
    clear,
    init,
    $reset,
  }
})
declare global {
  type FitmentKey = FitmentSlugKey | FitmentTextKey
  type FitmentSlugKey = keyof Pick<Fitment, 'year' | 'makeSlug' | 'modelSlug' | 'bedSlug' | 'bodySlug' | 'engineSlug'>
  type FitmentTextKey = keyof Pick<Fitment, 'year' | 'make' | 'model' | 'bed' | 'body' | 'engine'>
  interface Fitment {
    year?: string
    make?: string
    makeSlug?: string
    model?: string
    modelSlug?: string
    bed?: string
    bedSlug?: string
    body?: string
    bodySlug?: string
    engine?: string
    engineSlug?: string
  }
}

const FitmentKeys = [
  'year',
  'make',
  'makeSlug',
  'model',
  'modelSlug',
  'bed',
  'bedSlug',
  'body',
  'bodySlug',
  'engine',
  'engineSlug',
] as const
