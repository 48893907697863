export default defineNuxtPlugin(() => {
  const { $bugsnag } = useNuxtApp()
  if (import.meta.browser && $bugsnag) $bugsnag.leaveCrumb('integration installernet')

  const cart = useCart()

  interface State {
    quotes: Record<string, string>
  }
  const state = ref<State>({
    quotes: {},
  })

  function clearQuotes() {
    state.value.quotes = {}
  }

  function setQuote(sku: string, quoteId?: string | null) {
    // We will recreate the Quotes object each time
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [sku]: id, ...quotes } = state.value.quotes
    // We are adding the changed/new record if it exists
    state.value.quotes = {
      ...quotes,
      ...(quoteId && { [sku]: quoteId }),
    }
  }

  // Add To Cart will need to fetch a quote by sku
  function getQuoteIdBySku(sku: string) {
    return state.value.quotes[sku]
  }

  async function addInstallation(itemId: string, quoteId: string, city: string, state: string) {
    await cart.addItemInstallation(itemId, {
      quoteId,
      city,
      state,
    })
  }

  async function removeInstallation(itemId: string) {
    await cart.removeItemInstallation(itemId)
  }

  async function updateInstallation(itemId: string, city: string, state: string, zip: string) {
    await cart.updateItemInstallation(itemId, {
      city,
      state,
      zip,
    })
  }

  return {
    provide: {
      installernet: {
        setQuote,
        getQuoteIdBySku,
        addInstallation,
        removeInstallation,
        updateInstallation,
        clearQuotes,
        state,
      },
    },
  }
})
