export default defineNuxtPlugin(async () => {
  const domain = useState('sitewide-domain', () => '')
  const query = useRoute().query

  if (import.meta.server) {
    const host = useRequestHeader('host')!.replace(/:\d+/, '')

    domain.value = host === 'localhost' ? 'staging.realtruck.com' : host
  }

  const { data, error } = await useFetch(`/api/${domain.value}/sitewide`, {
    key: 'sitewidefetch',
    query: { timeTravel: query.timeTravel },
  })

  // TODOLATERER: Change the FE service to throw an error instead of an empty object
  // if Storyblok fails. Update this error check when we do.

  // Sitewide will always return an object, but at the very least the config should exist
  if (error.value || !data.value?.config) throw new Error(`Could not get Sitewide data for domain (${domain.value})!`)

  const navbar = shallowRef(data.value.navbar)

  onNuxtReady(async () => {
    const log = useLogger()

    const navbarData = await $fetch(`/api/${domain.value}/sitewide/navbar`)

    if (!navbarData) {
      log.error('NAVBAR_ERROR: fetching navbar', { error })
      return
    }

    navbar.value = navbarData
  })

  return {
    provide: {
      sitewideConfig: {
        config: data.value.config.content,
        header: data.value.header?.content,
        sitewideBanner: data.value.sitewideBanner,
        footer: data.value.footer?.content,
        navbar, // can't use .value or reactivity breaks
        seo: data.value.seo,
        styles: data.value.styles,
        channelId: data.value.channelId,
        domain: domain.value,
        sitePrefix: data.value.sitePrefix,
        linkBar: data.value.linkBar?.content,
      },
    },
  }
})

declare global {
  interface LinkBar {
    component: 'link-bar'
    standardLinkbar: Widget[]
    simpleLinkbar: Widget[]
  }

  interface Header {
    component: 'header'
    headerSimple: Widget[]
    headerStandard: Widget[]
    stickyEnabled: boolean
  }

  interface Footer {
    component: 'footer'
    footerSimple: Widget[]
    footerStandard: Widget[]
  }

  // link-menu, tree-menu, text-menu-item, cms-menu
  interface Navbar {
    component: 'navbar'
    items: LinkMenu[] | TextMenuItem[] | CmsMenu[] | TreeMenu[]
    desktopTextColor: string
    fitmentTextColor: string
    desktopHoverColor: string
    desktopHoverStyle: string
    desktopHoverTextColor: string
    desktopBackgroundColor: string
    fitmentBackgroundColor: string
    desktopBottomBorderColor: string
    hideOnSticky: boolean
  }
}
