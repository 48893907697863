export default function () {
  const { $yotpo } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()

  onMounted(() => {
    if (!isIntegrationEnabled('yotpo')) return

    $yotpo.addCallback(() => {
      try {
        // V3 refresher for Reviews and QA
        if (window.yotpoWidgetsContainer) {
          window.yotpoWidgetsContainer.initWidgets(false)
        }
      } catch (err) {
        // do nothing, we hate you yotpo..
      }
    })

    $yotpo.load()
  })
}
